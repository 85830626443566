import React, { useEffect, useState } from "react";
import { usePageContext } from "../components/context/PageContext";
import { SEO } from "../components/seo/seo";
import * as styles from "../styles/pages/iframe.module.scss";
import { Script } from "gatsby";

const widgetContainerId = "frameIngenieDevis";
const DevisPage = () => {
  const { lang } = usePageContext();

  const [scriptReady, setScriptReady] = useState<boolean>(false);
  const [script2Ready, setScript2Ready] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== "undefined" && scriptReady && script2Ready) {
      const frame = new window.FrameIngenie(
        lang === "fr"
          ? "https://reservation.tours-tourisme.fr/demande-de-devis-CVB-iframe.html"
          : "https://booking.tours-tourisme.fr/demande-de-devis-CVB-iframe.html",
        widgetContainerId
      );
      frame.init();
    }
  }, [lang, scriptReady, script2Ready]);

  return (
    <>
      <SEO />
      <Script
        async
        src="https://static.ingenie.fr/js/iframeResizer/easyXDM.min.js"
        type="text/javascript"
        onLoad={() => setScriptReady(true)}
      />
      <Script
        async
        src="https://reservation.tours-tourisme.fr/JsIframeResizer.js"
        type="text/javascript"
        onLoad={() => setScript2Ready(true)}
      />

      <div className={styles.wrapper}>
        <div id={widgetContainerId} />
      </div>
    </>
  );
};

export default DevisPage;
